import React from "react"

import ScrollSection from "../locomotiveScroll/scrollSection"
import InsightsItem from "../insights-item/insights-item"

import styles from "./insights-items.module.scss"

const InsightsItems = ({ items }) => {
  return (
    <ScrollSection>
      <div className={styles.insightsItems}>
        {React.Children.toArray(
          items.map(item => {
            return (
              <InsightsItem
                date={item.date}
                title={item.title}
                type={item.type}
                slug={item.slug}
                description={item.shortDescription.shortDescription}
                image={item.heroImage}
              />
            )
          })
        )}
      </div>
    </ScrollSection>
  )
}

export default InsightsItems
