import React, { useContext, useEffect } from "react"
import { ContextDispatch } from "./../../context/Context"

//Components
import Footer from "../../components/footer/footer"
import InsightsHero from "../../components/insights-hero/insights-hero"
import InsightsItems from "../../components/insights-items/insights-items"
import NextPage from "../../components/next-page/next-page"
import SEO from "../../components/seo/seo"

const InsightsPage = props => {
  const page = props,
    templateData = page.data.contentfulInsightsPage

  const storeDispatch = useContext(ContextDispatch)

  useEffect(() => {
    const header = () => {
      storeDispatch({ type: "HEADER_DARK" })
    }
    header()
  }, [storeDispatch])

  let nextpageblock

  if (templateData.nextPage) {
    nextpageblock = (
      <NextPage
        heading={templateData.nextPage.heading}
        title={templateData.nextPage.linkTitle}
        url={`/` + templateData.nextPage.page.slug}
      />
    )
  } else {
    nextpageblock = (
      <NextPage
        heading={`Ready To Talk?`}
        title={`Get In Touch`}
        url={``}
        contact={true}
      />
    )
  }

  return (
    <React.Fragment>
      {templateData.seoData && (
        <SEO
          title={templateData.seoData.seoTitle}
          image={templateData.seoData.seoImage}
          description={templateData.seoData.seoDescription}
        />
      )}
      <InsightsHero title={templateData.heroTitle} />
      <InsightsItems items={templateData.whitepapers} />
      {nextpageblock}
      <Footer />
    </React.Fragment>
  )
}

export default InsightsPage

export const pageQuery = graphql`
  query insightsPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulInsightsPage(slug: { eq: $slug }) {
      ...insightsPageQuery
    }
  }
`
