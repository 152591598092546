import React from "react"
import Img from "gatsby-image"

import { InView } from "react-intersection-observer"

import Button from "../button/button"

import styles from "./insights-item.module.scss"

const InsightsItem = ({ date, title, type, slug, description, image }) => {
  const buttonProps = {
    buttonText: "Read More",
    typeOfLink: {
      slug: `insights/${slug}`,
    },
  }

  return (
    <InView triggerOnce={true} threshold={0.2}>
      {({ inView, ref, entry }) => (
        <div
          className={`cols flex flex--center flex--vert-align ${styles.insightsItem}`}
          ref={ref}
          inview={inView.toString()}
        >
          <div className={`${styles.itemContent} col m12 t6 d7`}>
            <p className={styles.itemYear}>
              {type ? `${type} / ` : ""}
              {date}
            </p>
            <h2 className={`h3 ${styles.itemTitle}`}>{title}</h2>
            <p className={styles.itemDescription}>{description}</p>
            <Button buttonProps={buttonProps} buttonTheme="light" />
          </div>

          <div className={`${styles.itemImage} col m12 t4 d3`}>
            <Img fluid={image.fluid} alt={image.description} />
          </div>
        </div>
      )}
    </InView>
  )
}

export default InsightsItem
