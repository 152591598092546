import React from "react"

import ScrollSection from "../locomotiveScroll/scrollSection"

import styles from "./insights-hero.module.scss"

const InsightsHero = ({ title }) => {
  return (
    <ScrollSection>
      <div className={styles.insightsHero}>
        <div className={`col m12 t10 ${styles.container}`}>
          <h1 className="h2">{title}</h1>
        </div>
      </div>
    </ScrollSection>
  )
}

export default InsightsHero
